import React, { useContext, useRef, useState, useEffect } from 'react';
import Page from 'src/components/Page';
import {
  Button,
  Container,
  Grid,
  makeStyles,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  withStyles,
  Tooltip,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  TextField,
  Divider,
  TableContainer,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TablePagination,
} from '@material-ui/core';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAttendance,
  attendanceReducer,
  getWorkerAttendance,
  selectWorker,
  approveAttendance,
  getAttendanceReport,
  selectAProject,
  markAttendance,
} from 'src/redux/attendance';
import { getSkills, jobReducer } from 'src/redux/jobs';
import FilterProjectComponent from 'src/components/FilterProjectComponent';
import {
  getProjectsClassificationList,
  projectReducer,
} from 'src/redux/projects';

import { downloadExcel } from 'react-export-table-to-excel';
import SortSelection from 'src/components/SortSelection';
import SearchInput from 'src/components/SearchInput';
import defaultProjectImage from '../../assests/Projects Icon.png';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { Link } from 'react-router-dom';
import Zoom from '@material-ui/core/Zoom';
import EventNoteIcon from '@material-ui/icons/EventNote';
import moment from 'moment';
import { DateRangePicker } from 'react-date-range';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useToasts } from 'react-toast-notifications';
import CloseIcon from '@material-ui/icons/Close';
import { Autocomplete } from '@material-ui/lab';
import FilterByLabourContractorComponent from 'src/components/FilterByLaourContractorComponent';
import UserContext from 'src/context/UserContext';
import { getDARReport, getDLRReport, reportsReducer } from 'src/redux/reports';
import toast, { Toaster } from 'react-hot-toast';
import 'moment-timezone';
import { getContractors } from 'src/redux/users';
import DatePicker from 'react-datepicker';
import { markWorkerJob } from 'src/redux/workers';
import { breakString, getLoggedInUserData, userTypes } from 'src/App';
import { getUserLocation } from 'src/services/getUserLatLng';
import useAccessPerView from 'src/common/Access';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  table: {
    minWidth: 650,
  },

  DARAndDLRColums: {
    color: '#80b634',
    fontSize: '11px',
    fontWeight: 'bold',
  },

  buttonGreen: {
    color: theme.palette.common.white,
    backgroundColor: '#81B734',
    '&:hover': {
      backgroundColor: '#81B734',
    },
  },
  buttonPurple: {
    color: '#A179F2',
    backgroundColor: '#A179F2',
    fontFamily: 'Roboto',
    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },
  buttonPurpleOpacity: {
    color: '#986FEF',
    fontFamily: 'Roboto',
    backgroundColor: 'rgba(161, 120, 242, 0.20)',
    '&:hover': {
      backgroundColor: 'rgba(161, 120, 242, 0.20)',
    },
  },
  headerCellText: {
    fontSize: '11px',
    fontWeight: 500,
    fontFamily: 'Roboto',
    lineHeight: '13.75px',
  },
  headerCellText1: {
    fontSize: '10px',
    fontWeight: 600,
    fontFamily: 'Roboto',
    color: '#ADBAC3',
  },
  marginLeft: {
    marginLeft: '5px',
  },
  marginTop: { marginTop: '0' },
  overflow: { overflowX: 'hidden' },
  headerButton: {
    fontSize: '12px',
    // padding: '5px 15px',
    // "&:hover": {
    // 	backgroundColor: "#81B734",
    // 	color: "white",
    // },
  },
  statusPaddingLeft: {
    paddingLeft: '10px',
  },
  paddingLeftMark: {
    paddingLeft: '10px',
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  searchBarMarginBotto: {
    marginBottom: '0',
  },

  [theme.breakpoints.down('sm')]: {
    marginTop: { marginTop: '1rem' },
    marginLeft: {
      marginLeft: '0',
    },
    overflow: { overflowX: 'scroll' },
    statusPaddingLeft: {
      paddingLeft: '0',
    },
    paddingLeftMark: {
      paddingLeft: '0',
    },
    searchBarMarginBotto: {
      marginBottom: '10px',
    },
  },
  [theme.breakpoints.down('md')]: {
    marginDownloadButton: {
      marginTop: '15px',
    },
    paddingLeftMark: {
      paddingLeft: '0',
    },
    statusPaddingLeft: {
      paddingLeft: '0',
    },
  },
}));

function Attendance() {
  const classes = useStyles();
  const Accesses = {};
  Accesses['ATTENDANCE'] = useAccessPerView('ATTENDANCE');

  const [filters, setFilters] = useState({
    search: '',
    sortBy: 0,
    filterBy: 0,
    searchModal: '',
    filterModalBy: 0,
  });
  const [approveModal, setApproveModal] = useState(false);
  const [filteredArray, setFilteredArray] = useState([]);
  const [modalArray, setModalArray] = useState([]);
  const [modalFilteredArray, setModalFilteredArray] = useState([]);
  // const [DLRDate, setDLRDate] = useState("");
  // const [DARDate, setDARDate] = useState("");

  const [assignAttendance, setAssignAttendance] = useState(null);
  const [LabourContractor, setLabourContractor] = useState(0);

  const [LabourContractorModal, setLabourContractorModal] = useState(0);
  const [DAR, setDAR] = useState(false);
  const [DLR, setDLR] = useState(false);
  const [userData, setUserData] = useContext(UserContext);
  const [sortingData, setSortingData] = useState(0);

  const [paginationData, setPaginationData] = useState({
    page: 0,
    rowsPerPage: 25,
    count: 100,
  });

  // date state
  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
      noValue: true,
    },
  ]);
  const [open, setOpen] = useState(false);
  const refOne = useRef(null);
  const tableScrollUpRef = useRef(null);
  //! INSTANCES
  let dispatch = useDispatch();

  //! SELECTORS
  const {
    loading,
    attendanceList,
    approvingLoading,
    selectedProject,
    markingAttendanceLoading,
  } = useSelector(attendanceReducer);
  const { skillsList } = useSelector(jobReducer);
  const { projectClassificationList } = useSelector(projectReducer);
  const [classifiedProject, setClassifiedProject] = useState('');
  const [currentProject, setCurrentProject] = useState('');
  const [artificialLoading, setArtificialLoading] = useState(false);

  const { dlrList, darList } = useSelector(reportsReducer);
  // console.log("classifiedProject", classifiedProject);
  const [reasonValue, setReasonValue] = useState('');
  const [userCurrentLocation, setUserCurrentLocation] = useState({});

  //! LIFE-CYCLES

  const handleProjectData = (project) => {
    setCurrentProject(project);
    setClassifiedProject([project]);

    dispatch(getDLRReport(project.projectId, LabourContractor?.userId));
    dispatch(getDARReport(project.projectId, LabourContractor?.userId));
    dispatch(getContractors(project?.projectId));
  };

  const fetchLocationOfUser = async () => {
    let data = await getUserLocation();
    setUserCurrentLocation(data);
  };

  useEffect(() => {
    fetchLocationOfUser();
    if (projectClassificationList.length === 0) {
      dispatch(getProjectsClassificationList());
      dispatch(getSkills());
    }

    if (projectClassificationList.length > 0) {
      handleProjectData(projectClassificationList[0]);
      if (!attendanceList) {
        dispatch(
          getAttendance(
            projectClassificationList[0]?.projectId,
            LabourContractor?.userId,
            false,
            1,
            0,
            1000
          )
        );
      }
    }
  }, [projectClassificationList.length]);

  useEffect(() => {
    if (attendanceList?.attendances.length)
      setModalArray([...attendanceList?.attendances]);
  }, [attendanceList?.attendances?.length]);

  useEffect(() => {
    // event listeners
    document.addEventListener('keydown', hideOnEscape, true);
    document.addEventListener('click', hideOnClickOutside, true);
  }, []);
  // hide dropdown on ESC press
  const hideOnEscape = (e) => {
    // console.log(e.key)
    if (e.key === 'Escape') {
      setOpen(false);
    }
  };

  // Hide dropdown on outside click
  const hideOnClickOutside = (e) => {
    // console.log(refOne.current)
    // console.log(e.target)
    if (refOne.current && !refOne.current.contains(e.target)) {
      setOpen(false);
    }
  };

  //! METHODS
  //! TABLE DATA - EXCEL
  const header = [
    'WORKER NAME',
    'SKILL SET',
    'WORKING DAYS',
    'PRESENT DAYS',
    'ABSENT DAYS',
    'INCOMPLETE DAYS',
  ];
  const body = attendanceList?.attendance?.map((attendance) => [
    attendance?.workerName ?? 'N/A',
    attendance?.sKillName ?? 'N/A',

    attendance?.workingDays?.length
      ? attendance?.workingDays?.length
      : attendance?.workingDays ?? 'N/A',

    attendance?.presentDays?.length
      ? attendance?.presentDays?.length
      : attendance?.presentDays ?? 'N/A',
    attendance?.absentDays?.length
      ? attendance?.absentDays?.length
      : attendance?.absentDays ?? 'N/A',
    attendance?.incompleteDays?.length ?? 'N/A',
  ]);
  let length = attendanceList?.attendances?.length;

  const skillCounts = {};
  for (let i = 0; i < attendanceList?.attendances?.length; i++) {
    const skillName = attendanceList?.attendances[i].sKillName;
    if (skillCounts[skillName]) {
      skillCounts[skillName]++;
    } else {
      skillCounts[skillName] = 1;
    }
  }

  const arr = [];
  for (const [key, value] of Object.entries(skillCounts)) {
    arr.push({ key, value });
  }
  let skillsInArray = [];
  for (let item of arr) {
    skillsInArray.push([
      item.key.toUpperCase(),
      '',
      '',
      '',
      '',
      '',
      item.value,
    ]);
  }

  async function handleDownloadExcel() {
    let resp;
    if (range[0]?.noValue) {
      resp = await dispatch(
        getAttendanceReport(
          currentProject?.projectId,
          moment().startOf('month').format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD')
        )
      );
    } else {
      resp = await dispatch(
        getAttendanceReport(
          currentProject?.projectId,
          moment(range[0]?.startDate).format('YYYY-MM-DD'),
          moment(range[0]?.endDate).format('YYYY-MM-DD')
        )
      );
    }
    if (resp?.status === 200) {
      let totalManDays = attendanceList?.reduce(
        (total, item) => total + item.presentDays?.length,
        0
      );

      const skillCounts = {};
      for (let i = 0; i < attendanceList?.attendances?.length; i++) {
        const skillName = attendanceList?.attendances[i].sKillName;
        if (skillCounts[skillName]) {
          skillCounts[skillName]++;
        } else {
          skillCounts[skillName] = 1;
        }
      }

      const arr = [];
      for (const [key, value] of Object.entries(skillCounts)) {
        arr.push({ key, value });
      }
      let skillsInArray = [];
      for (let item of arr) {
        skillsInArray.push([item.key.toUpperCase(), '', item.value]);
      }
      const excelBody = [
        ...body,
        ['-', '-', '-', '-', '-', '-'],
        ['TOTAL WORKERS', '', length],
        ['TOTAL MAN DAYS', '', totalManDays],
        ['', '', '', '', '', ''],

        ['SKILLS', '', 'TOTAL'],
      ];
      skillsInArray.forEach((value) => excelBody.push(value));
      downloadExcel({
        fileName: `AttendanceList_${currentProject?.name}`,
        sheet: 'Attendance Sheet - Bettamint',
        tablePayload: {
          header,
          // accept two different data structures
          body: excelBody,
        },
      });
    }
  }
  //! FILTERS
  const handleFilters = (e) => {
    const { value } = e.target;

    if (value !== 0) {
      setFilters({
        ...filters,
        sortBy: value,
      });
      if (
        attendanceList?.attendances?.filter(
          (attendance) => attendance.sKillName === value
        ).length === 0 &&
        value != 0
      )
        return setFilteredArray(null);

      setFilteredArray(
        attendanceList?.attendances.filter(
          (attendance) => attendance.sKillName === value
        )
      );
    }
  };
  const handleSearch = (e) => {
    const { value } = e.target;

    setFilters({
      ...filters,
      search: value,
    });

    if (value) {
      const data =
        parseInt(filters.sortBy) !== 0
          ? filteredArray
          : attendanceList?.attendances;

      let newFilter = data.filter((item) => {
        return Object.values(item)
          .join('')
          .toLowerCase()
          .includes(value.toLowerCase());
      });
      setFilteredArray(newFilter);
    }

    if (!value) {
      return setFilteredArray([]);
    }
  };

  const handleSearchForModal = (e) => {
    const { value } = e.target;

    setFilters({
      ...filters,
      searchModal: value,
    });

    if (value) {
      let newFilter = modalArray.filter((item) => {
        return Object.values(item)
          .join('')
          .toLowerCase()
          .includes(value.toLowerCase());
      });
      setModalFilteredArray(newFilter);
    }

    if (!value) {
      return setModalFilteredArray([]);
    }
  };

  const handleFiltersStatusButton = (value, providedArray) => {
    setFilters({ ...filters, filterModalBy: value });

    let array = providedArray ?? modalArray;

    if (value === 'Active') {
      let data = array?.filter((worker) => worker?.isOnline === true);
      if (data?.length > 0) {
        return setModalFilteredArray(data);
      } else {
        return setModalFilteredArray(null);
      }
    }
    if (value === 'NonActive') {
      let data = array?.filter((worker) => worker?.isOnline === false);
      if (data?.length > 0) {
        return setModalFilteredArray(data);
      } else {
        return setModalFilteredArray(null);
      }
    }

    if (
      array?.filter((worker) => worker?.workerType === value && value != 0)
        ?.length === 0
    )
      return setModalFilteredArray(null);

    setModalFilteredArray(
      array?.filter((worker) => worker?.workerType === value)
    );
  };

  const classifyProject = async (project) => {
    if (project) {
      const { projectId } = project;
      dispatch(getDLRReport(project.projectId, LabourContractor?.userId));
      dispatch(getDARReport(project.projectId, LabourContractor?.userId));
      setCurrentProject(project);
      setClassifiedProject(
        projectClassificationList.filter(
          (project) => project.projectId === projectId && project
        )
      );
      dispatch(getContractors(projectId));
      setLabourContractor(0);
      let resp = await dispatch(
        getAttendance(projectId, 0, false, 1, 0, 10000)
      );
      if (resp?.data?.result?.attendances?.length) {
        setFilters({ ...filters, filterModalBy: 'Active' });
        // handleFiltersStatusButton("Online")
        setModalArray([...resp?.data?.result?.attendances]);
        handleFiltersStatusButton('Active', [
          ...resp?.data?.result?.attendances,
        ]);

        if (
          resp?.data?.result?.attendances?.filter(
            (worker) => worker?.isOnline === true
          ).length === 0
        )
          return setModalFilteredArray(null);
      } else {
        setModalFilteredArray(
          resp?.data?.result?.attendances?.filter(
            (worker) => worker?.isOnline === true
          )
        );
        handleFiltersStatusButton('Active');
      }

      dispatch(selectAProject(project));
      // dispatch(getDLRReport(projectId, 0));
      // dispatch(getDARReport(projectId, 0));
      setPaginationData({
        page: 0,
        rowsPerPage: 25,
        count: 10000,
      });
    } else {
      setLabourContractor(0);
      dispatch(getContractors(projectClassificationList[0]?.projectId));

      dispatch(
        getAttendance(
          projectClassificationList[0]?.projectId,
          0,
          false,
          1,
          0,
          1000
        )
      );
      setCurrentProject(projectClassificationList[0]);
      dispatch(selectAProject(projectClassificationList[0]));

      dispatch(
        getDLRReport(
          projectClassificationList[0]?.projectId,
          LabourContractor?.userId
        )
      );
      dispatch(
        getDARReport(
          projectClassificationList[0]?.projectId,
          LabourContractor?.userId
        )
      );
      setPaginationData({
        page: 0,
        rowsPerPage: 25,
        count: 10000,
      });

      return setClassifiedProject(projectClassificationList[0]);
    }
  };

  const redirectToDetailPage = (worker) => {
    dispatch(getWorkerAttendance(worker.jobId, worker.workerId, false));
    dispatch(selectWorker(worker));
    dispatch(selectAProject(currentProject));
  };

  const handleFiltersStatus = (e) => {
    const { value } = e.target;

    setFilters({ ...filters, filterBy: value });
    if (value == 0) return setFilteredArray([]);

    if (value === 'Active') {
      let data = attendanceList?.attendances?.filter(
        (worker) => worker?.isOnline === true
      );
      if (data.length > 0) {
        return setFilteredArray(data);
      } else {
        return setFilteredArray(null);
      }
    }
    if (value === 'NonActive') {
      let data = attendanceList?.attendances?.filter(
        (worker) => worker?.isOnline === false
      );
      if (data.length > 0) {
        return setFilteredArray(data);
      } else {
        return setFilteredArray(null);
      }
    }
    if (
      attendanceList?.attendances?.filter(
        (worker) => worker?.workerType === value && value != 0
      ).length === 0
    )
      return setFilteredArray(null);

    setFilteredArray(
      attendanceList?.attendances?.filter(
        (worker) => worker?.workerType === value
      )
    );
  };

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      // maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
      borderRadius: '10px',
    },
  }))(Tooltip);

  const handleDateRange = (update) => {
    let date = update[0];

    if (
      moment(date.startDate).format('LL') === moment().format('LL') &&
      moment(date.endDate).format('LL') === moment().format('LL')
    ) {
      const dynamicAttendanceList =
        filteredArray?.length > 0 ? filteredArray : attendanceList?.attendances;

      let newList = dynamicAttendanceList?.filter((item) => {
        let days = item?.presentDays?.filter((day) => {
          const presentDay = new Date(day).setHours(0, 0, 0, 0);

          if (date.startDate.getTime() === presentDay) {
            return day;
          }
        });

        if (days.length > 0) {
          return item;
        }
      });

      if (newList?.length === 0) {
        return setFilteredArray(null);
      } else {
        return setFilteredArray(newList);
      }
    } else {
      const dynamicAttendanceList =
        filteredArray?.length > 0 ? filteredArray : attendanceList?.attendances;

      let newList = dynamicAttendanceList?.filter((item) => {
        let days = item?.presentDays?.filter((day) => {
          const presentDay = new Date(day).setHours(0, 0, 0, 0);

          if (
            presentDay >= date.startDate.getTime() &&
            presentDay <= date.endDate.getTime()
          )
            return day;
        });
        if (days.length > 0) {
          return item;
        }
      });
      if (newList?.length === 0) {
        return setFilteredArray(null);
      } else {
        return setFilteredArray(newList);
      }
    }
  };
  const handleAttendanceApprove = async (obj) => {
    let resp = await dispatch(
      approveAttendance(
        obj?.jobId,
        obj?.workerId,
        moment.utc().format(),
        obj?.value?.hours,
        obj?.value?.name
      )
    );
    console.log('filterBY', filters);

    if (resp?.status === 200) {
      toast.success('Attendance Approved Successfully!', {
        position: 'top-right',
      });

      let res = await dispatch(
        getAttendance(currentProject?.projectId, 0, false, 1, 0, 1000)
      );

      if (res?.status === 200) {
        console.log(
          'modalFilteredArray1',
          res?.data?.result?.attendances?.filter((worker) =>
            filters.filterModalBy === 'Active'
              ? worker?.isOnline
              : filters.filterModalBy === worker.workerType
          )
        );
        if (
          res?.data?.result?.attendances?.filter((worker) =>
            filters.filterModalBy === 'Active'
              ? worker?.isOnline
              : filters.filterModalBy === worker.workerType
          ).length === 0
        )
          return setModalFilteredArray(null);

        setModalFilteredArray(
          res?.data?.result?.attendances?.filter((worker) =>
            filters.filterModalBy === 'Active'
              ? worker?.isOnline
              : filters.filterModalBy === worker.workerType
          )
        );
        console.log('modalFilteredArray2', modalFilteredArray);
      }
    } else {
      toast.error(resp?.data?.error, {
        position: 'top-right',
      });
    }
  };
  const openAttendanceApproveModal = async () => {
    setApproveModal(true);
    handleFiltersStatusButton('Active', attendanceList?.attendances);
  };
  const handleLabourContractor = (val) => {
    setLabourContractor(val);
    dispatch(
      getAttendance(currentProject?.projectId, val?.userId, false, 1, 0, 1000)
    );
    dispatch(getDLRReport(currentProject?.projectId, val?.userId));
    dispatch(getDARReport(currentProject?.projectId, val?.userId));
    setPaginationData({
      page: 0,
      rowsPerPage: 25,
      count: 100,
    });
  };
  const handleLabourContractorModal = async (val) => {
    setArtificialLoading(true);
    setLabourContractorModal(val);
    let resp = await dispatch(
      getAttendance(currentProject?.projectId, val?.userId, false, 1, 0, 10000)
    );

    if (resp?.status === 200) {
      setArtificialLoading(false);
      setModalArray(resp?.data?.result?.attendances);
      handleFiltersStatusButton(
        filters?.filterModalBy,
        resp?.data?.result?.attendances
      );
    }
  };

  const openDARModal = () => {
    setDAR(true);
  };

  const openDLRModal = () => {
    setDLR(true);
  };
  const handleDownloadExcelDAR = async () => {
    let resp = await dispatch(
      getDARReport(currentProject.projectId, LabourContractor?.userId, true)
    );

    const contentType = resp.headers['content-type'];

    if (resp.status === 200) {
      if (
        contentType !==
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        return toast.error('Invalid file format. Expected Excel file.');
      }

      const fileData = new Blob([resp.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      const fileURL = window.URL.createObjectURL(fileData);
      const link = document.createElement('a');
      link.href = fileURL;
      link.setAttribute('download', `DAR.xlsx`);

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else if (resp.status === 500) {
      toast.error('No Data Found!');
    } else {
      toast.error('Something went wrong!');
    }
  };

  const handleDownloadExcelDLR = async () => {
    let resp = await dispatch(
      getDLRReport(currentProject.projectId, LabourContractor?.userId, true)
    );

    const contentType = resp.headers['content-type'];

    if (resp.status === 200) {
      if (contentType === 'application/json; charset=utf-8') {
        return toast.error('No Data Found!');
      }
      if (resp.data.success === false) return toast.error('No Data Found!');

      toast.success('Excel file downloading');
      const fileURL = window.URL.createObjectURL(new Blob([resp.data]));
      // Create a temporary anchor element
      const link = document.createElement('a');
      link.href = fileURL;
      // Set the download attribute if you want to specify a file name
      link.setAttribute('download', `DLR.xlsx`);

      // Append the link to the DOM
      document.body.appendChild(link);

      // Trigger a click to initiate the download
      link.click();

      // Clean up
      document.body.removeChild(link);
    } else if (resp.status === 500) {
      toast.error('No Data Found!');
    } else {
      toast.error('Something went wrong!');
    }
    return;
  };
  const renderAttendenceList = () => {
    return (
      <Grid
        container
        // style={{ marginTop: '2em' }}
      >
        <Grid
          container
          item
          xs={12}
          justify="space-between"
          style={{ marginBottom: 15 }}
        >
          <Grid
            container
            item
            md={7}
            xs={12}
            justify="flex-start"
            alignItems="center"
          >
            <Grid
              item
              container
              justify="flex-start"
              alignItems="center"
              md={7}
              xs={12}
            >
              <Grid>
                <Typography
                  style={{
                    fontSize: 16,
                    fontWeight: 600,
                    fontFamily: 'Roboto',
                  }}
                >
                  Attendance |
                </Typography>
              </Grid>
              <Grid>
                <FilterProjectComponent
                  projectClassificationList={projectClassificationList}
                  src={
                    !classifiedProject[0]?.url
                      ? defaultProjectImage
                      : `${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${classifiedProject[0]?.url}`
                  }
                  value={currentProject}
                  onChange={classifyProject}
                />
              </Grid>
            </Grid>
            {userData?.user.leadTypeId !== 'LabourContractor' && (
              <Grid item md={5} xs={12}>
                <FilterByLabourContractorComponent
                  value={LabourContractor}
                  onChange={handleLabourContractor}
                  setInitialUser={(user) => setLabourContractor(user)}
                  currentProject={projectClassificationList[0]}
                />
              </Grid>
            )}
          </Grid>

          <Grid
            item
            container
            md={4}
            xs={12}
            justify="space-between"
            alignItems="center"
            className={classes.marginTop}
          >
            <Grid
              container
              item
              // xs={4}
              alignItems="center"
              justify="space-between"
            >
              <Button
                variant="outlined"
                size="small"
                style={{
                  // color: '#81B734',
                  cursor: 'pointer',
                  textTransform: 'capitalize',
                }}
                className={classes.buttonPurpleOpacity}
                onClick={openAttendanceApproveModal}
              >
                {/* {moment().format('LL')} */}
                Today's Attendance
              </Button>
              <Button
                variant="outlined"
                size="small"
                style={{
                  // color: '#81B734',
                  cursor: 'pointer',
                  textTransform: 'capitalize',
                }}
                className={classes.buttonPurpleOpacity}
                onClick={openDARModal}
              >
                {/* {moment().format('LL')} */}
                DAR
              </Button>
              <Button
                variant="outlined"
                size="small"
                style={{
                  // color: '#81B734',
                  cursor: 'pointer',
                  textTransform: 'capitalize',
                }}
                className={classes.buttonPurpleOpacity}
                onClick={openDLRModal}
              >
                {/* {moment().format('LL')} */}
                DLR
              </Button>
              {/* <Grid item> */}
              <Divider orientation="vertical" flexItem />
              {/* </Grid> */}

              <Grid item>
                <HtmlTooltip
                  title={
                    <Grid
                      style={{
                        fontWeight: '400',
                        // padding: '5 10px',
                        // borderRadius: '10px',
                        // border: '1px solid #ddd',
                      }}
                    >
                      Attendance is validated via{' '}
                      <i style={{ fontWeight: 600 }}>two-factor</i>{' '}
                      authentication i.e. Worker Check-In & Geolocation Tracking
                      during work hours.
                    </Grid>
                  }
                >
                  <button
                    // size='small'
                    // className={` ${classes.headerButton}`}
                    // variant='outlined'
                    style={{
                      backgroundColor: '#FDD9D9',
                      color: 'red',
                      padding: '4px 3px 2px 3px',
                      border: 'none',
                      borderRadius: '3px',
                      cursor: 'pointer',
                    }}
                    onClick={handleDownloadExcel}
                  >
                    <ErrorOutlineIcon
                      style={{ width: '20px', height: '20px' }}
                    />
                  </button>
                </HtmlTooltip>
              </Grid>
              <Grid
                item
                container
                justify="center"
                alignItems="center"
                style={{
                  background: '#81B734',
                  width: 30,
                  height: 30,
                  borderRadius: '5px',
                  // padding: '5px'
                  cursor: 'pointer',
                }}
                onClick={handleDownloadExcel}
              >
                <SystemUpdateAltIcon
                  fontSize="small"
                  style={{ color: 'white', textAlign: 'center' }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* //! Filter Container */}
        <Grid
          xs={12}
          container
          // spacing={3}
          // style={{ marginTop: '1rem' }}
          alignItems="center"
          className="input-container-nav"
          justify="space-between"
          // style={ { border: '2px solid green' } }
        >
          {/* container */}

          {/* //! 1.SortInput */}
          <Grid
            item
            md={3}
            xs={12}
            container
            alignItems="center"
            justify="space-between"
            // className='padding5'
            // style={{ padding: '2px' }}
            className={classes.marginTop}
          >
            <Grid item xs={1}>
              <small>Skills: </small>
            </Grid>
            <Grid item md={10} xs={9}>
              <SortSelection
                initialValue="Skills"
                value={filters.sortBy}
                handleFilters={handleFilters}
                data={skillsList?.map((skill) => ({
                  value: skill.name,
                  label: skill.name,
                }))}
              />
            </Grid>
          </Grid>

          {/* //! 2.DatePickerInput */}
          <Grid
            item
            md={3}
            xs={12}
            container
            justify="space-between"
            alignItems="center"
            className={`${classes.marginRight} ${classes.marginTop}`}
            // style={ { border: '2px solid red' } }
            // className={ classes.marginTop }
          >
            <Grid item md={10} xs={9}>
              <Autocomplete
                id="combo-box-demo"
                options={[
                  { name: 'A to Z', value: 0 },
                  { name: 'Z to A', value: 1 },
                ]}
                getOptionLabel={(option) => option.name}
                style={{ width: 200 }}
                renderInput={(params) => (
                  <TextField {...params} label="Sort By" />
                )}
                onChange={(e, value) => gettingAttendanceBySorting(value)}
                value={
                  [
                    { name: 'A to Z', value: 0 },
                    { name: 'Z to A', value: 1 },
                  ][sortingData]
                }
              />
            </Grid>
          </Grid>

          <Grid
            item
            md={2}
            xs={12}
            container
            alignItems="center"
            justify="space-between"
            // className='padding5'

            // style={{ padding: '2px', border: '1px solid red' }}
            className={`${classes.marginLeft} ${classes.marginTop}`}
          >
            {/* <Grid item md={3} xs={3}> */}
            <small>Status:</small>
            {/* </Grid> */}
            <Grid
              item
              xs={9}
              md={9}
              // className={ classes.statusPaddingLeft }
            >
              <SortSelection
                value={filters.filterBy}
                initialValue={'Status'}
                handleFilters={handleFiltersStatus}
                data={[
                  {
                    value: 'Online',
                    label: 'Online',
                  },
                  {
                    value: 'Offline',
                    label: 'Offline',
                  },
                  // {
                  // 	value: 'Active',
                  // 	label: 'Active',
                  // },
                  // {
                  // 	value: 'NonActive',
                  // 	label: 'Non Active',
                  // },
                ]}
              />
            </Grid>
          </Grid>

          {/* //! 3.SearchInput */}
          <Grid
            item
            md={3}
            xs={12}
            container
            justify="space-between"
            alignItems="center"
            className={classes.marginTop}
          >
            <Grid item md={1} xs={3}>
              <small style={{ paddingRight: '15px' }}>Search:</small>
            </Grid>
            <Grid item xs={9} md={10} style={{ position: 'relative' }}>
              <SearchInput
                search={filters.search}
                handleSearch={handleSearch}
                // className='font12'
              />
            </Grid>
          </Grid>
        </Grid>
        {loading ? (
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ paddingTop: '1rem' }}
          >
            <CircularProgress style={{ color: '#B2FF59' }} />
          </Grid>
        ) : attendanceList?.length === 0 ? (
          <Grid
            container
            justify="center"
            xs={12}
            style={{ padding: '1rem 0' }}
          >
            No Search Found!
          </Grid>
        ) : (
          <Grid container item xs={12} className={classes.overflow}>
            <Zoom in={attendanceList?.attendances?.length ? true : false}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      size="small"
                      align="center"
                      // style={{ fontSize: 12, fontFamily: 'Roboto' }}
                      className={classes.headerCellText1}
                    >
                      #
                    </TableCell>
                    <TableCell
                      size="small"
                      align="center"
                      // style={{ fontSize: 12, fontFamily: 'Roboto' }}
                      className={classes.headerCellText1}
                    >
                      JOB ID
                    </TableCell>
                    <TableCell
                      size="small"
                      align="center"
                      // style={{ fontSize: 12, fontFamily: 'Roboto' }}
                      className={classes.headerCellText1}
                    >
                      WORKER NAME
                    </TableCell>
                    <TableCell
                      size="small"
                      align="center"
                      // style={{ fontSize: 12, fontFamily: 'Roboto' }}
                      className={classes.headerCellText1}
                    >
                      SKILL SET
                    </TableCell>
                    {/*
											<TableCell
												size='small'
												align='center'
												// style={{ fontSize: 12, fontFamily: 'Roboto' }}
												className={classes.headerCellText1}>
												DESCRIPTION
											</TableCell> */}
                    {/* <TableCell
												size='small'
												align='center'
												// style={{ fontSize: 12, fontFamily: 'Roboto' }}
												className={classes.headerCellText1}>
												START - END DATE
											</TableCell> */}
                    <TableCell
                      size="small"
                      align="center"
                      // style={{ fontSize: 12, fontFamily: 'Roboto' }}
                      className={classes.headerCellText1}
                    >
                      WORKING DAYS
                    </TableCell>
                    <TableCell
                      size="small"
                      align="center"
                      // style={{ fontSize: 12, fontFamily: 'Roboto' }}
                      className={classes.headerCellText1}
                    >
                      PRESENT DAYS
                    </TableCell>
                    <TableCell
                      size="small"
                      align="center"
                      // style={{ fontSize: 12, fontFamily: 'Roboto' }}
                      className={classes.headerCellText1}
                    >
                      ABSENT DAYS
                    </TableCell>
                    {/* <TableCell
												size='small'
												align='center'
												// style={{ fontSize: 12, fontFamily: 'Roboto' }}
												className={classes.headerCellText1}>
												STATUS
											</TableCell> */}
                    {/* <TableCell
										align='center'
										style={{ fontSize: 12, fontFamily: 'Roboto' }}
									>
										INCOMPLETE DAYS
									</TableCell> */}
                    <TableCell
                      size="small"
                      align="center"
                      // style={{ fontSize: 12, fontFamily: 'Roboto' }}
                      className={classes.headerCellText1}
                    >
                      ACTIONS
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {filteredArray?.length ? (
                    filteredArray?.map((val, index) => (
                      <TableRow hover key={index}>
                        <TableCell
                          align="center"
                          style={{ fontSize: 12, fontFamily: 'Roboto' }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ fontSize: 12, fontFamily: 'Roboto' }}
                        >
                          {val?.jobId ?? 'N/A'}
                        </TableCell>
                        <TableCell>
                          <Grid container alignItems="center">
                            <Typography
                              // align='center'
                              style={{
                                fontSize: 12,
                                fontWeight: 'bold',
                                fontFamily: 'Roboto',
                                textAlign: 'center',
                                width: '100%',
                              }}
                            >
                              {val?.workerName ?? 'N/A'}
                            </Typography>
                          </Grid>
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ fontSize: 12, fontFamily: 'Roboto' }}
                        >
                          {val?.sKillName ?? 'N/A'}
                        </TableCell>
                        {/*
													<TableCell
														align='center'
														style={{ fontSize: 12, fontFamily: 'Roboto' }}>
														{val?.jobDescription ?? 'N/A'}
													</TableCell> */}
                        {/* <TableCell
														align='center'
														className={classes.headerCellText}
														width='15%'>
														<div
															className='flex'
															style={{ paddingBottom: '5px' }}>
															<div className='circle-green'></div>
															{moment(val.startDate).format('DD MMM YYYY')}
														</div>
														<div className='flex'>
															<div className='circle-red'></div>
															{moment(val.endDate).format(' DD MMM YYYY')}
														</div>
													</TableCell> */}
                        <TableCell
                          align="center"
                          style={{ fontSize: 12, fontFamily: 'Roboto' }}
                        >
                          {val?.workingDays?.length
                            ? val?.workingDays?.length
                            : val?.workingDays ?? 'N/A'}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ fontSize: 12, fontFamily: 'Roboto' }}
                        >
                          {val?.presentDays?.length
                            ? val?.presentDays?.length
                            : val?.presentDays ?? 'N/A'}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ fontSize: 12, fontFamily: 'Roboto' }}
                        >
                          {val?.absentDays?.length
                            ? val?.absentDays?.length
                            : val?.absentDays ?? 'N/A'}
                        </TableCell>
                        {/* <TableCell
														align='center'
														style={{ fontSize: 12, fontFamily: 'Roboto' }}>
														{val.workerType ?? 'N/A'}
													</TableCell> */}

                        {/* <TableCell
												align='center'
												style={{ fontSize: 12, fontFamily: 'Roboto' }}
											>
												{val?.incompleteDays?.length ?? 'N/A'}
											</TableCell> */}
                        <TableCell align="center" width="15%">
                          <Grid container xs={12} direction="column">
                            <Grid xs={11} justify="center" alignItems="center">
                              <Grid item xs={12} style={{ margin: '10px 0' }}>
                                <Link
                                  to="detail"
                                  style={{ color: 'rgba(152, 111, 239, 1)' }}
                                >
                                  <Button
                                    fullWidth
                                    className={classes.buttonPurpleOpacity}
                                    style={{
                                      fontSize: 11,
                                      textTransform: 'capitalize',
                                      borderRadius: '10px',
                                      padding: '.7rem',
                                      fontWeight: 600,
                                    }}
                                    onClick={() => redirectToDetailPage(val)}
                                  >
                                    View Details
                                  </Button>
                                </Link>
                              </Grid>
                              <Grid item xs={12}>
                                <Button
                                  // onClick={() => openEndJobModal(val)}
                                  fullWidth
                                  variant="contained"
                                  // disabled={val.isCompleted}
                                  disabled
                                  className={`${classes.buttonPurpleOpacity}`}
                                  style={{
                                    // backgroundColor: "#81B734",
                                    backgroundColor: '#c2c1cd',

                                    color: '#fff',
                                    padding: '.5rem .5rem',
                                    borderRadius: '9px',
                                    fontSize: '11px',
                                    fontWeight: 500,
                                    textTransform: 'capitalize',
                                    opacity: val.isCompleted ? 0.5 : 1,
                                  }}
                                  size="small"
                                  // startIcon={<ReceiptIcon />}
                                  // onClick={() => openDetails(val)}
                                >
                                  {'End Job'}
                                </Button>
                              </Grid>
                              <Grid item xs={12}>
                                <FormControl fullWidth>
                                  <InputLabel
                                    id="demo-simple-select-label"
                                    style={{ fontSize: '14px' }}
                                  >
                                    Remove Worker
                                  </InputLabel>
                                  <Select
                                    onChange={(e) =>
                                      markReason(e.target.value, val)
                                    }
                                  >
                                    <MenuItem value={reasonValue}>
                                      None
                                    </MenuItem>
                                    <MenuItem value="NoShow">No Show</MenuItem>
                                    <MenuItem value={'Tardiness'}>
                                      Tardiness
                                    </MenuItem>
                                    <MenuItem value={'Misconduct'}>
                                      Misconduct
                                    </MenuItem>
                                    <MenuItem value={'JobEnded'}>
                                      Job Ended
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : filters.search !== '' || filteredArray === null ? (
                    <TableRow>No Search Found!</TableRow>
                  ) : (
                    attendanceList?.attendances?.map((val, index) => (
                      <TableRow hover key={index}>
                        <TableCell
                          align="center"
                          style={{ fontSize: 12, fontFamily: 'Roboto' }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ fontSize: 12, fontFamily: 'Roboto' }}
                        >
                          {val?.jobId ?? 'N/A'}
                        </TableCell>
                        <TableCell width="15%">
                          <Grid container alignItems="center">
                            <Typography
                              // align='center'
                              style={{
                                fontSize: 12,
                                fontWeight: 'bold',
                                fontFamily: 'Roboto',
                                textAlign: 'center',
                                width: '100%',
                              }}
                            >
                              {val?.workerName ?? 'N/A'}
                            </Typography>
                          </Grid>
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ fontSize: 12, fontFamily: 'Roboto' }}
                        >
                          {val?.sKillName ?? 'N/A'}
                        </TableCell>
                        {/*
													<TableCell
														align='center'
														style={{ fontSize: 12, fontFamily: 'Roboto' }}>
														{val?.jobDescription ?? 'N/A'}
													</TableCell> */}
                        {/* <TableCell
														align='center'
														className={classes.headerCellText}
														width='15%'>
														<div
															className='flex'
															style={{ paddingBottom: '5px' }}>
															<div className='circle-green'></div>
															{moment(val.startDate).format('DD MMM YYYY')}
														</div>
														<div className='flex'>
															<div className='circle-red'></div>
															{moment(val.endDate).format(' DD MMM YYYY')}
														</div>
													</TableCell> */}
                        <TableCell
                          align="center"
                          style={{ fontSize: 12, fontFamily: 'Roboto' }}
                        >
                          {val?.workingDays?.length
                            ? val?.workingDays?.length
                            : val?.workingDays ?? 'N/A'}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ fontSize: 12, fontFamily: 'Roboto' }}
                        >
                          {val?.presentDays?.length
                            ? val?.presentDays?.length
                            : val?.presentDays ?? 'N/A'}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ fontSize: 12, fontFamily: 'Roboto' }}
                        >
                          {val?.absentDays?.length
                            ? val?.absentDays?.length
                            : val?.absentDays ?? 'N/A'}
                        </TableCell>
                        {/* <TableCell
														align='center'
														style={{ fontSize: 12, fontFamily: 'Roboto' }}>
														{val.workerType ?? 'N/A'}
													</TableCell> */}
                        {/* <TableCell
												align='center'
												style={{ fontSize: 12, fontFamily: 'Roboto' }}
											>
												{val?.incompleteDays ?? "N/A" ?? 'N/A'}
											</TableCell> */}
                        <TableCell align="center" width="15%">
                          <Grid container xs={12} direction="column">
                            <Grid xs={11} justify="center" alignItems="center">
                              <Grid item xs={12} style={{ margin: '10px 0' }}>
                                <Link
                                  to="detail"
                                  style={{ color: 'rgba(152, 111, 239, 1)' }}
                                >
                                  <Button
                                    fullWidth
                                    className={classes.buttonPurpleOpacity}
                                    style={{
                                      fontSize: 11,
                                      textTransform: 'capitalize',
                                      borderRadius: '10px',
                                      padding: '.7rem',
                                      fontWeight: 600,
                                    }}
                                    onClick={() => redirectToDetailPage(val)}
                                  >
                                    View Details
                                  </Button>
                                </Link>
                              </Grid>
                              <Grid item xs={12}>
                                <Button
                                  // onClick={() => openEndJobModal(val)}
                                  fullWidth
                                  variant="contained"
                                  // disabled={val.isCompleted}
                                  disabled
                                  className={`${classes.buttonPurpleOpacity}`}
                                  style={{
                                    // backgroundColor: "#81B734",
                                    backgroundColor: '#c2c1cd',
                                    color: '#fff',
                                    padding: '.5rem .5rem',
                                    borderRadius: '9px',
                                    fontSize: '11px',
                                    fontWeight: 500,
                                    textTransform: 'capitalize',
                                    opacity: val.isCompleted ? 0.5 : 1,
                                  }}
                                  size="small"
                                  // startIcon={<ReceiptIcon />}
                                  // onClick={() => openDetails(val)}
                                >
                                  {'End Job'}
                                </Button>
                              </Grid>
                              <Grid item xs={12}>
                                <FormControl fullWidth>
                                  <InputLabel
                                    id="demo-simple-select-label"
                                    style={{ fontSize: '14px' }}
                                  >
                                    Remove Worker
                                  </InputLabel>
                                  <Select
                                    onChange={(e) =>
                                      markReason(e.target.value, val)
                                    }
                                  >
                                    <MenuItem value={reasonValue}>
                                      None
                                    </MenuItem>
                                    <MenuItem value="NoShow">No Show</MenuItem>
                                    <MenuItem value={'Tardiness'}>
                                      Tardiness
                                    </MenuItem>
                                    <MenuItem value={'Misconduct'}>
                                      Misconduct
                                    </MenuItem>
                                    <MenuItem value={'JobEnded'}>
                                      Job Ended
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </Zoom>
          </Grid>
        )}
        <Grid container item justify="flex-end" alignItems="center">
          <TablePagination
            component="div"
            count={attendanceList?.totalCount}
            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
            rowsPerPage={paginationData?.rowsPerPage}
            page={paginationData?.page}
            colSpan={3}
            onChangePage={handleChangePage}
          />
        </Grid>
      </Grid>
    );
  };

  const handleOfflineWorkerChecks = async (worker, jobId, attendanceType) => {
    let resp = await dispatch(
      markAttendance(
        worker?.workerId,
        jobId,
        attendanceType,
        userCurrentLocation?.latitude,
        userCurrentLocation?.longitude
      )
    );
    if (resp?.status === 200) {
      let value = filters?.filterModalBy;
      let respp = await dispatch(
        getAttendance(
          currentProject?.projectId
            ? currentProject?.projectId
            : projectClassificationList[0]?.projectId,
          LabourContractorModal?.userId,
          false,
          1,
          0,
          1000
        )
      );
      if (respp?.status === 200) {
        toast.success('Attendance Marked Successfully!');

        let newDataList = respp.data.result?.attendances;
        setModalArray(newDataList);
        setModalFilteredArray(newDataList);
        handleFiltersStatusButton(value, newDataList);

        // if (value === 'Active') {
        //   let data = newDataList?.filter((worker) => worker?.isOnline === true);
        //   if (data.length > 0) {
        //     return setModalFilteredArray(data);
        //   } else {
        //     return setModalFilteredArray(null);
        //   }
        // }
        // if (value === 'NonActive') {
        //   let data = newDataList?.filter(
        //     (worker) => worker?.isOnline === false
        //   );
        //   if (data.length > 0) {
        //     return setModalFilteredArray(data);
        //   } else {
        //     return setModalFilteredArray(null);
        //   }
        // }

        // if (
        //   newDataList?.filter(
        //     (worker) => worker?.workerType === value && value != 0
        //   ).length === 0
        // )
        //   return setModalFilteredArray(null);

        // setModalFilteredArray(
        //   newDataList?.filter((worker) => worker?.workerType === value)
        // );

        // setModalFilteredArray(
        //   newDataList?.filter(
        //     (worker) => worker?.workerType === filters?.filterModalBy
        //   )
        // );

        //! AFTER ATTENDANCE MARK NEW LIST API (CURRENTLY DISABLED)
      }
      // setModalFilteredArray(
      //   newAttendanceListResp?.data?.filter(
      //     (worker) => worker?.workerType === "Offline"
      //   )
      // );
      // setModalArray([...newAttendanceListResp.data]);
      // toast.success("Attendance Marked Successfully!");
      // if (tableScrollUpRef.current) {
      //   tableScrollUpRef.current.scrollIntoView({
      //     behavior: "smooth",
      //     block: "start",
      //   });
      // }
    } else {
      toast.error('Error: while marking attendance!');
    }
  };
  const markReason = async (value, row) => {
    setReasonValue(value);

    if (value !== '') {
      let resp = await dispatch(
        markWorkerJob({
          workerId: row.workerId,
          jobId: row.jobId,
          reason: value,
        })
      );

      if (resp.status === 200) {
        toast.success('Worker Removed Successfully From This Job!');
        dispatch(
          getAttendance(
            currentProject.projectId,
            LabourContractor?.userId,
            false,
            1,
            0,
            1000
          )
        );
      } else {
        toast.error('Something went wrong!');
      }
    }
  };

  const handleChangePage = (event, newPage) => {
    setPaginationData({ ...paginationData, page: newPage });

    dispatch(
      getAttendance(
        currentProject.projectId,
        LabourContractor?.userId,
        false,
        newPage + 1
      )
    );
  };

  const gettingAttendanceBySorting = (value) => {
    if (!value) {
      setSortingData(0);
      return dispatch(
        getAttendance(
          currentProject.projectId,
          LabourContractor?.userId,
          false,
          paginationData?.page + 1,
          0
        )
      );
    }
    setSortingData(value.value);
    dispatch(
      getAttendance(
        currentProject.projectId,
        LabourContractor?.userId,
        false,
        paginationData?.page + 1,
        value.value
      )
    );
  };

  return (
    <Page className={classes.root} id="zoom-level" title="Attendance">
      <Container maxWidth={false}>
        <Dialog
          // fullScreen
          maxWidth={'lg'}
          fullWidth={'lg'}
          open={approveModal}
          onClose={() => {
            // setModalArray(null);
            // setModalFilteredArray(null);

            setApproveModal(false);
          }}
          // TransitionComponent={ Transition }
        >
          <AppBar
            className={classes.appBar}
            style={{ backgroundColor: '#A179F2' }}
          >
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => setApproveModal(false)}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Grid container justify="space-between" alignItems="center">
                <Grid
                  item
                  container
                  justify="flex-start"
                  alignItems="center"
                  md={6}
                  xs={12}
                  // style={{ border: "2px solid red" }}
                  className={classes.marginTop}
                >
                  <Grid item style={{ margin: '0 5px' }}>
                    <Button
                      fullWidth
                      className={` ${classes.headerButton} ${classes.buttonFullSize}`}
                      variant="outlined"
                      style={{
                        textTransform: 'capitalize',
                        color:
                          filters.filterModalBy === 'Active'
                            ? 'white'
                            : 'white',
                        backgroundColor:
                          filters.filterModalBy === 'Active' ? '#81B734' : '',
                      }}
                      onClick={() =>
                        handleFiltersStatusButton(
                          'Active',
                          attendanceList?.attendances
                        )
                      }
                    >
                      Present {attendanceList?.presentCount ?? 'N/A'}
                    </Button>
                  </Grid>
                  <Grid item style={{ margin: '0 5px' }}>
                    <Button
                      fullWidth
                      className={` ${classes.headerButton} ${classes.buttonFullSize}`}
                      variant="outlined"
                      style={{
                        textTransform: 'capitalize',
                        color:
                          filters.filterModalBy === 'NonActive'
                            ? 'white'
                            : 'white',
                        backgroundColor:
                          filters.filterModalBy === 'NonActive'
                            ? '#81B734'
                            : '',
                      }}
                      onClick={() =>
                        handleFiltersStatusButton(
                          'NonActive',
                          attendanceList?.attendances
                        )
                      }
                    >
                      Absent {attendanceList?.absentCount ?? 'N/A'}
                    </Button>
                  </Grid>
                  <Grid item style={{ margin: '0 5px' }}>
                    <Button
                      className={` ${classes.headerButton} ${classes.buttonFullSize}`}
                      variant="outlined"
                      fullWidth
                      style={{
                        textTransform: 'capitalize',
                        color: 'white',
                        backgroundColor:
                          filters.filterModalBy === 'Online' ? '#81B734' : '',
                      }}
                      onClick={() =>
                        handleFiltersStatusButton(
                          'Online',
                          attendanceList?.attendances
                        )
                      }
                    >
                      Online {attendanceList?.onlineCount ?? 'N/A'}
                    </Button>
                  </Grid>
                  <Grid item style={{ margin: '0 5px' }}>
                    <Button
                      fullWidth
                      className={` ${classes.headerButton} ${classes.buttonFullSize}`}
                      variant="outlined"
                      style={{
                        textTransform: 'capitalize',
                        color:
                          filters.filterModalBy === 'Offline'
                            ? 'white'
                            : 'white',
                        backgroundColor:
                          filters.filterModalBy === 'Offline' ? '#81B734' : '',
                      }}
                      onClick={() =>
                        handleFiltersStatusButton(
                          'Offline',
                          attendanceList?.attendances
                        )
                      }
                    >
                      Offline {attendanceList?.offlineCount ?? 'N/A'}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Typography>
                      Total:{' '}
                      {attendanceList?.absentCount +
                        attendanceList?.presentCount}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                  // style={{ border: '2px solid red' }}
                  className={`${classes.marginTop} ${classes.searchBarMarginBotto}`}
                  container
                  justify="flex-end"
                  alignItems="center"
                >
                  {userData?.user.leadTypeId !== 'LabourContractor' && (
                    <Grid
                      item
                      md={6}
                      xs={12}
                      container
                      alignItems="center"
                      justify="space-between"
                      // style={{ border: '2px solid red' }}
                    >
                      <Grid item xs={12} style={{ paddingRight: '10px' }}>
                        <FilterByLabourContractorComponent
                          color="white"
                          value={LabourContractorModal}
                          onChange={handleLabourContractorModal}
                          // setCont={(user) => console.log("test", user)}
                          setInitialUser={(user) =>
                            setLabourContractorModal(user)
                          }
                          showFilterByProject={true}
                          currentProject={currentProject}
                        />
                      </Grid>
                    </Grid>
                  )}
                  <Grid item md={6} xs={12} container alignItems="center">
                    <Grid item>
                      <small style={{ paddingRight: '10px' }}>Search:</small>
                    </Grid>
                    <Grid item xs={10} md={9} style={{ position: 'relative' }}>
                      <SearchInput
                        search={filters.searchModal}
                        handleSearch={handleSearchForModal}
                        // className='font12'
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>

          <Grid container item xs={12} className={classes.overflow}>
            {modalArray?.length === 0 ? (
              <Grid
                container
                justify="center"
                xs={12}
                style={{ padding: '1rem 0' }}
              >
                No Search Found!
              </Grid>
            ) : (
              <Zoom in={modalArray?.length ? true : false}>
                <Table ref={tableScrollUpRef} style={{ overflowX: 'hidden' }}>
                  <TableHead>
                    {(approvingLoading || markingAttendanceLoading) && (
                      <Grid
                        container
                        justify="center"
                        alignItems="center"
                        style={{
                          paddingTop: '1rem',
                          position: 'absolute',
                          top: '50%',
                        }}
                      >
                        <CircularProgress style={{ color: '#c2c1cd' }} />
                      </Grid>
                    )}
                    <TableRow>
                      <TableCell
                        size="small"
                        align="center"
                        // style={{ fontSize: 12, fontFamily: 'Roboto' }}
                        className={classes.headerCellText1}
                      >
                        #
                      </TableCell>
                      <TableCell
                        size="small"
                        align="center"
                        // style={{ fontSize: 12, fontFamily: 'Roboto' }}
                        className={classes.headerCellText1}
                      >
                        JOB ID
                      </TableCell>
                      <TableCell
                        size="small"
                        align="center"
                        // style={{ fontSize: 12, fontFamily: 'Roboto' }}
                        className={classes.headerCellText1}
                      >
                        WORKER NAME
                      </TableCell>
                      <TableCell
                        size="small"
                        align="center"
                        // style={{ fontSize: 12, fontFamily: 'Roboto' }}
                        className={classes.headerCellText1}
                      >
                        SKILL SET
                      </TableCell>
                      <TableCell
                        size="small"
                        align="center"
                        // style={{ fontSize: 12, fontFamily: 'Roboto' }}
                        className={classes.headerCellText1}
                      >
                        STATUS
                      </TableCell>
                      <TableCell
                        size="small"
                        align="center"
                        // style={{ fontSize: 12, fontFamily: 'Roboto' }}
                        className={classes.headerCellText1}
                      >
                        CHECKIN
                      </TableCell>
                      <TableCell
                        size="small"
                        align="center"
                        // style={{ fontSize: 12, fontFamily: 'Roboto' }}
                        className={classes.headerCellText1}
                      >
                        CHECKOUT
                      </TableCell>
                      <TableCell
                        size="small"
                        align="center"
                        // style={{ fontSize: 12, fontFamily: 'Roboto' }}
                        className={classes.headerCellText1}
                      >
                        REGION
                      </TableCell>

                      <TableCell
                        size="small"
                        align="center"
                        // style={{ fontSize: 12, fontFamily: 'Roboto' }}
                        className={classes.headerCellText1}
                      >
                        ACTIONS
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  {artificialLoading ? (
                    <Grid
                      container
                      justify="center"
                      alignItems="center"
                      style={{ paddingTop: '1rem', width: '100%' }}
                    >
                      <CircularProgress style={{ color: '#B2FF59' }} />
                    </Grid>
                  ) : (
                    <TableBody>
                      {modalFilteredArray?.length ? (
                        modalFilteredArray?.map((val, index) => (
                          <TableRow hover key={index}>
                            <TableCell
                              align="center"
                              style={{ fontSize: 12, fontFamily: 'Roboto' }}
                            >
                              {index + 1}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ fontSize: 12, fontFamily: 'Roboto' }}
                            >
                              {val?.jobId ?? 'N/A'}
                            </TableCell>
                            <TableCell>
                              <Grid container alignItems="center">
                                <Typography
                                  // align='center'
                                  style={{
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                    fontFamily: 'Roboto',
                                    textAlign: 'center',
                                    width: '100%',
                                  }}
                                >
                                  {val?.workerName ?? 'N/A'}
                                </Typography>
                              </Grid>
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ fontSize: 12, fontFamily: 'Roboto' }}
                            >
                              {val?.sKillName ?? 'N/A'}
                            </TableCell>

                            <TableCell
                              align="center"
                              style={{ fontSize: 12, fontFamily: 'Roboto' }}
                            >
                              {val?.workerType}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ fontSize: 12, fontFamily: 'Roboto' }}
                            >
                              {val.todayCheckIn ? (
                                moment(val.todayCheckIn)
                                  .add(330, 'minutes')
                                  .format('LLL')
                              ) : (
                                <Button
                                  size="small"
                                  className={classes.buttonGreen}
                                  style={{ textTransform: 'capitalize' }}
                                  onClick={() =>
                                    handleOfflineWorkerChecks(
                                      val,
                                      val?.jobId,
                                      'CheckIn'
                                    )
                                  }
                                  // Enabled only for Active users
                                  disabled={!Accesses['ATTENDANCE'].isEditMode}
                                >
                                  Check-in
                                </Button>
                              )}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ fontSize: 12, fontFamily: 'Roboto' }}
                            >
                              {val.todayCheckOut
                                ? moment(val.todayCheckOut)
                                    .add(330, 'minutes')
                                    .format('LLL')
                                : val?.todayCheckIn && (
                                    <Button
                                      size="small"
                                      className={classes.buttonPurpleOpacity}
                                      style={{ textTransform: 'capitalize' }}
                                      onClick={() =>
                                        handleOfflineWorkerChecks(
                                          val,
                                          val?.jobId,
                                          'CheckOut'
                                        )
                                      }
                                      // Enabled only for Active users
                                      disabled={
                                        !Accesses['ATTENDANCE'].isEditMode
                                      }
                                    >
                                      Check-out
                                    </Button>
                                  )}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ fontSize: 12, fontFamily: 'Roboto' }}
                            >
                              {val?.tower === '' ? '-' : val.tower}
                            </TableCell>

                            <TableCell align="center" width="15%">
                              <Grid container xs={12} direction="column">
                                {/* <Grid item xs={12} style={{ margin: '10px 0' }}>
															<Link
																to='detail'
																style={{ color: 'rgba(152, 111, 239, 1)' }}>
																<Button
																	fullWidth
																	className={classes.buttonPurpleOpacity}
																	style={{
																		fontSize: 11,
																		textTransform: 'capitalize',
																		borderRadius: '10px',
																		padding: '.7rem',
																		fontWeight: 600,
																	}}
																	onClick={() => redirectToDetailPage(val)}>
																	View Details
																</Button>
															</Link>
														</Grid> */}
                                {/* {(val.workerType === 'Offline' ||
																val.workerType === 'Online') && (
																)} */}
                                {userData?.user?.role?.name.toLowerCase() ===
                                  'supervisor' &&
                                val.skillTypeId.toLowerCase() ===
                                  'supervisor' ? null : !val?.todayCheckIn ||
                                  !val?.todayCheckOut ? null : (
                                  <Autocomplete
                                    size="small"
                                    // value={value}
                                    onChange={(event, newValue) => {
                                      if (newValue === null) {
                                        return setAssignAttendance(null);
                                      }
                                      setAssignAttendance({
                                        jobId: val?.jobId,
                                        workerId: val?.workerId,
                                        value: newValue,
                                      });
                                      handleAttendanceApprove({
                                        jobId: val?.jobId,
                                        workerId: val?.workerId,
                                        value: newValue,
                                      });
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    options={[
                                      { name: 'A', hours: 0 },
                                      { name: '1/2 P', hours: 4 },
                                      { name: 'P', hours: 8 },
                                      { name: 'P1', hours: 9 },
                                      { name: 'P2', hours: 10 },
                                      { name: 'P3', hours: 11 },
                                      { name: 'P4', hours: 12 },
                                      { name: 'P5', hours: 13 },
                                      { name: 'P6', hours: 14 },
                                      { name: 'P7', hours: 15 },
                                      { name: 'PP', hours: 16 },
                                    ]}
                                    style={{ width: 170 }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Approve Status"
                                        variant="outlined"
                                        className={classes.projectInput}
                                      />
                                    )}
                                  />
                                )}
                              </Grid>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : filters.searchModal !== '' ||
                        modalFilteredArray === null ? (
                        <TableRow>No Search Found!</TableRow>
                      ) : (
                        modalArray?.map((val, index) => (
                          <TableRow hover key={index}>
                            <TableCell
                              align="center"
                              style={{ fontSize: 12, fontFamily: 'Roboto' }}
                            >
                              {index + 1}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ fontSize: 12, fontFamily: 'Roboto' }}
                            >
                              {val?.jobId ?? 'N/A'}
                            </TableCell>
                            <TableCell>
                              <Grid container alignItems="center">
                                <Typography
                                  // align='center'
                                  style={{
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                    fontFamily: 'Roboto',
                                    textAlign: 'center',
                                    width: '100%',
                                  }}
                                >
                                  {val?.workerName ?? 'N/A'}
                                </Typography>
                              </Grid>
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ fontSize: 12, fontFamily: 'Roboto' }}
                            >
                              {val?.sKillName ?? 'N/A'}
                            </TableCell>

                            <TableCell
                              align="center"
                              style={{ fontSize: 12, fontFamily: 'Roboto' }}
                            >
                              {val?.workerType}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ fontSize: 12, fontFamily: 'Roboto' }}
                            >
                              {val.todayCheckIn ? (
                                moment(val.todayCheckIn)
                                  .add(330, 'minutes')
                                  .format('LLL')
                              ) : (
                                <Button
                                  size="small"
                                  className={classes.buttonGreen}
                                  style={{ textTransform: 'capitalize' }}
                                  onClick={() =>
                                    handleOfflineWorkerChecks(
                                      val,
                                      val?.jobId,
                                      'CheckIn'
                                    )
                                  }
                                  // Enabled only for Active users
                                  disabled={!Accesses['ATTENDANCE'].isEditMode}
                                >
                                  Check-in
                                </Button>
                              )}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ fontSize: 12, fontFamily: 'Roboto' }}
                            >
                              {val.todayCheckOut
                                ? moment(val.todayCheckOut)
                                    .add(330, 'minutes')
                                    .format('LLL')
                                : val.todayCheckIn && (
                                    <Button
                                      size="small"
                                      className={classes.buttonPurpleOpacity}
                                      style={{ textTransform: 'capitalize' }}
                                      onClick={() =>
                                        handleOfflineWorkerChecks(
                                          val,
                                          val?.jobId,

                                          'CheckOut'
                                        )
                                      }
                                      // Enabled only for Active users
                                      disabled={
                                        !Accesses['ATTENDANCE'].isEditMode
                                      }
                                    >
                                      Check-out
                                    </Button>
                                  )}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ fontSize: 12, fontFamily: 'Roboto' }}
                            >
                              {val?.tower === '' ? '-' : val.tower}
                            </TableCell>

                            <TableCell align="center" width="15%">
                              <Grid container xs={12} direction="column">
                                {/* <Grid item xs={12} style={{ margin: '10px 0' }}>
															<Link
																to='detail'
																style={{ color: 'rgba(152, 111, 239, 1)' }}>
																<Button
																	fullWidth
																	className={classes.buttonPurpleOpacity}
																	style={{
																		fontSize: 11,
																		textTransform: 'capitalize',
																		borderRadius: '10px',
																		padding: '.7rem',
																		fontWeight: 600,
																	}}
																	onClick={() => redirectToDetailPage(val)}>
																	View Details
																</Button>
															</Link>
														</Grid> */}
                                {/* {(val.workerType === 'Offline' ||
																val.workerType === 'Online') && (
																)} */}
                                {userData?.user?.role?.name.toLowerCase() ===
                                  'supervisor' &&
                                val.skillTypeId.toLowerCase() ===
                                  'supervisor' ? null : !val?.todayCheckIn ||
                                  !val?.todayCheckOut ? null : (
                                  <Autocomplete
                                    size="small"
                                    // value={value}
                                    onChange={(event, newValue) => {
                                      if (newValue === null) {
                                        return setAssignAttendance(null);
                                      }
                                      setAssignAttendance({
                                        jobId: val?.jobId,
                                        workerId: val?.workerId,
                                        value: newValue,
                                      });
                                      handleAttendanceApprove({
                                        jobId: val?.jobId,
                                        workerId: val?.workerId,
                                        value: newValue,
                                      });
                                    }}
                                    // inputValue={inputValue}
                                    // onInputChange={(event, newInputValue) => {
                                    // 	setInputValue(newInputValue);
                                    // }}
                                    getOptionLabel={(option) => option.name}
                                    options={[
                                      { name: 'A', hours: 0 },
                                      { name: '1/2 P', hours: 4 },
                                      { name: 'P', hours: 8 },
                                      { name: 'P1', hours: 9 },
                                      { name: 'P2', hours: 10 },
                                      { name: 'P3', hours: 11 },
                                      { name: 'P4', hours: 12 },
                                      { name: 'P5', hours: 13 },
                                      { name: 'P6', hours: 14 },
                                      { name: 'P7', hours: 15 },
                                      { name: 'PP', hours: 16 },
                                    ]}
                                    style={{ width: 170 }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Approve Status"
                                        // Enabled only for LEVEL 1 users
                                        disabled={
                                          !Accesses['ATTENDANCE'].hasAccess
                                            .LEVEL1
                                        }
                                        variant="outlined"
                                        className={classes.projectInput}
                                      />
                                    )}
                                  />
                                )}
                              </Grid>
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  )}
                </Table>
              </Zoom>
            )}
          </Grid>
        </Dialog>

        {/* DLR */}
        <Dialog
          // fullScreen
          maxWidth={'xl'}
          fullWidth={'xl'}
          open={DLR}
          onClose={() => setDLR(false)}
          // TransitionComponent={ Transition }
        >
          <AppBar
            className={classes.appBar}
            style={{
              backgroundColor: '#A179F2',
              // position: "absolute",
              // top: "0",
            }}
          >
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => setDLR(false)}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Grid item>DLR</Grid>
              <Grid container xs={12} alignItems="center" justify="flex-end">
                <Grid
                  item
                  container
                  justify="center"
                  alignItems="center"
                  style={{
                    background: '#81B734',
                    width: 30,
                    height: 30,
                    borderRadius: '5px',
                    // padding: '5px'
                    cursor: 'pointer',
                  }}
                  // onClick={handleDownloadExcelDLR}
                >
                  <SystemUpdateAltIcon
                    onClick={handleDownloadExcelDLR}
                    fontSize="small"
                    style={{ color: 'white', textAlign: 'center' }}
                  />
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>

          <Grid container xs={12} className={classes.overflow}>
            <Grid item xs={12} container style={{ padding: '1rem' }}>
              <Grid item md={4} container>
                <Grid container item md={11} xs={12} justify="space-between">
                  <Grid item xs={5}>
                    <Typography style={{ fontSize: '14px', fontWeight: 700 }}>
                      Project Name:{' '}
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography style={{ fontSize: '14px' }} align="start">
                      {dlrList?.projectName}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  md={11}
                  xs={12}
                  justify="space-between"
                  style={{}}
                >
                  <Grid item xs={5}>
                    <Typography style={{ fontSize: '14px', fontWeight: 700 }}>
                      {/* {breakString(
                        getLoggedInUserData()?.user?.leadTypeId !== null
                          ? getLoggedInUserData()?.user?.leadTypeId ===
                            "Contractor"
                            ? "GeneralContractor"
                            : getLoggedInUserData()?.user?.leadTypeId
                          : getLoggedInUserData()?.user?.userTypeId
                      )} */}
                      {dlrList?.title || 'N/A'}:
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography style={{ fontSize: '14px' }} align="start">
                      {dlrList?.company || 'N/A'}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  md={11}
                  xs={12}
                  justify="space-between"
                  style={{}}
                >
                  <Grid item xs={5}>
                    <Typography style={{ fontSize: '14px', fontWeight: 700 }}>
                      Date:
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography style={{ fontSize: '14px' }} align="start">
                      {moment().format('LL')}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item md={4} container>
                <Grid container item xs={12} md={11} justify="space-between">
                  <Grid item xs={5}>
                    <Typography style={{ fontSize: '14px', fontWeight: 700 }}>
                      Present:{' '}
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography style={{ fontSize: '14px' }} align="start">
                      {dlrList?.present}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  md={11}
                  justify="space-between"
                  style={{}}
                >
                  <Grid item xs={5}>
                    <Typography style={{ fontSize: '14px', fontWeight: 700 }}>
                      Absent:
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography style={{ fontSize: '14px' }} align="start">
                      {dlrList?.absent}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  md={11}
                  xs={12}
                  justify="space-between"
                  style={{}}
                >
                  <Grid item xs={5}>
                    <Typography style={{ fontSize: '14px', fontWeight: 700 }}>
                      Total Workers:
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography style={{ fontSize: '14px' }} align="start">
                      {dlrList?.totalWorkers}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item md={4} container>
                <Grid container item xs={12} justify="space-between">
                  <Grid item md={6} xs={5}>
                    <Typography style={{ fontSize: '14px', fontWeight: 700 }}>
                      Total Online Workers:
                    </Typography>
                  </Grid>
                  <Grid item md={6} xs={7}>
                    <Typography style={{ fontSize: '14px' }} align="start">
                      {dlrList?.totalOnline}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item xs={12} justify="space-between" style={{}}>
                  <Grid item md={6} xs={5}>
                    <Typography style={{ fontSize: '14px', fontWeight: 700 }}>
                      Total Offline Workers:
                    </Typography>
                  </Grid>
                  <Grid item md={6} xs={7}>
                    <Typography style={{ fontSize: '14px' }} align="start">
                      {dlrList?.totalOffline}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container item xs={12} justify="space-between" style={{}}>
                  <Grid item md={6} xs={5}>
                    <Typography style={{ fontSize: '14px', fontWeight: 700 }}>
                      Total Workers Quit:
                    </Typography>
                  </Grid>
                  <Grid item md={6} xs={7}>
                    <Typography style={{ fontSize: '14px' }} align="start">
                      {dlrList?.totalQuit}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} style={{ padding: '10px 0' }}>
              <Typography
                align="center"
                variant="subtitle1"
                style={{ fontWeight: 700 }}
                component="h2"
              >
                DAILY LABOUR REPORT
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table
                  className={classes.table}
                  size="small"
                  aria-label="DAR Table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className={classes.DARAndDLRColums}
                        align="center"
                      >
                        #
                      </TableCell>

                      <TableCell className={classes.DARAndDLRColums}>
                        Worker ID
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.DARAndDLRColums}
                      >
                        Worker Name
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.DARAndDLRColums}
                      >
                        Gender
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.DARAndDLRColums}
                      >
                        Contractor Name
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.DARAndDLRColums}
                      >
                        Skill Set
                      </TableCell>

                      <TableCell
                        align="left"
                        className={classes.DARAndDLRColums}
                      >
                        Skill Level
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.DARAndDLRColums}
                      >
                        Attendance
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.DARAndDLRColums}
                      >
                        Check In
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.DARAndDLRColums}
                      >
                        Check Out
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.DARAndDLRColums}
                      >
                        Region
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.DARAndDLRColums}
                      >
                        Overtime
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.DARAndDLRColums}
                      >
                        Quit Job
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.DARAndDLRColums}
                      >
                        Status
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.DARAndDLRColums}
                      >
                        Remark
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dlrList?.labourAttendance?.map((item, index) => (
                      <TableRow key={index + 1}>
                        <TableCell align="center" style={{ fontSize: '12px' }}>
                          {index + 1}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          style={{ fontSize: '12px' }}
                        >
                          {item?.workerId}
                        </TableCell>
                        <TableCell align="left" style={{ fontSize: '12px' }}>
                          {item.name}
                        </TableCell>
                        <TableCell align="left" style={{ fontSize: '12px' }}>
                          {item.gender}
                        </TableCell>
                        <TableCell align="left" style={{ fontSize: '12px' }}>
                          {item.contractor ?? 'N/A'}
                        </TableCell>

                        <TableCell align="left" style={{ fontSize: '12px' }}>
                          {item?.skillName === '' ? 'N/A' : item?.skillName}
                        </TableCell>
                        <TableCell align="left" style={{ fontSize: '12px' }}>
                          {item.skillLevel ?? 'N/A'}
                        </TableCell>
                        <TableCell align="left" style={{ fontSize: '12px' }}>
                          {item.attendance === true ? 'Present' : 'Absent'}
                        </TableCell>
                        <TableCell align="left" style={{ fontSize: '12px' }}>
                          {item.attendance
                            ? moment(item?.checkIn)
                                .add(330, 'minutes')
                                .format('LLL')
                            : '-'}
                        </TableCell>
                        <TableCell align="left" style={{ fontSize: '12px' }}>
                          {item.attendance
                            ? item?.checkOut === '0001-01-01T00:00:00'
                              ? '-'
                              : moment(item?.checkOut)
                                  .add(330, 'minutes')
                                  .format('LLL')
                            : '-'}
                        </TableCell>
                        <TableCell align="left" style={{ fontSize: '12px' }}>
                          {item.tower ?? 'N/A'}
                        </TableCell>
                        <TableCell align="left" style={{ fontSize: '12px' }}>
                          {item.overtimeHours}
                        </TableCell>
                        <TableCell align="left" style={{ fontSize: '12px' }}>
                          -
                        </TableCell>
                        <TableCell align="left" style={{ fontSize: '12px' }}>
                          {' '}
                          {item.status}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ fontSize: '12px' }}
                        ></TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Dialog>

        {/* DAR */}
        <Dialog
          // fullScreen
          maxWidth={'xl'}
          fullWidth={'xl'}
          open={DAR}
          onClose={() => setDAR(false)}
          // TransitionComponent={ Transition }
        >
          <AppBar
            className={classes.appBar}
            style={{ backgroundColor: '#A179F2' }}
          >
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => setDAR(false)}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Grid item>DAR</Grid>

              <Grid container xs={12} alignItems="center" justify="flex-end">
                <Grid
                  item
                  container
                  justify="center"
                  alignItems="center"
                  style={{
                    background: '#81B734',
                    width: 30,
                    height: 30,
                    borderRadius: '5px',
                    // padding: '5px'
                    cursor: 'pointer',
                  }}
                  onClick={handleDownloadExcelDAR}
                >
                  <SystemUpdateAltIcon
                    fontSize="small"
                    style={{ color: 'white', textAlign: 'center' }}
                  />
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          <Grid container xs={12} className={classes.overflow}>
            <Grid item xs={12} container style={{ padding: '1rem' }}>
              <Grid item md={4} container>
                <Grid container item md={11} xs={12} justify="space-between">
                  <Grid item xs={5}>
                    <Typography style={{ fontSize: '14px', fontWeight: 700 }}>
                      Project Name:{' '}
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography style={{ fontSize: '14px' }} align="start">
                      {darList?.projectName}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  md={11}
                  xs={12}
                  justify="space-between"
                  style={{}}
                >
                  <Grid item xs={5}>
                    <Typography style={{ fontSize: '14px', fontWeight: 700 }}>
                      {/* {breakString(
                        getLoggedInUserData()?.user?.leadTypeId !== null
                          ? getLoggedInUserData()?.user?.leadTypeId ===
                            "Contractor"
                            ? "GeneralContractor"
                            : getLoggedInUserData()?.user?.leadTypeId
                          : getLoggedInUserData()?.user?.userTypeId
                      )} */}
                      {darList?.title ?? 'N/A'}:
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography style={{ fontSize: '14px' }} align="start">
                      {darList?.company ?? 'N/A'}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  md={11}
                  xs={12}
                  justify="space-between"
                  style={{}}
                >
                  <Grid item xs={5}>
                    <Typography style={{ fontSize: '14px', fontWeight: 700 }}>
                      Date:
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography style={{ fontSize: '14px' }} align="start">
                      {moment().format('LL')}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item md={4} container>
                <Grid container item md={11} xs={12} justify="space-between">
                  <Grid item xs={5}>
                    <Typography style={{ fontSize: '14px', fontWeight: 700 }}>
                      Present:{' '}
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography style={{ fontSize: '14px' }} align="start">
                      {darList?.present ?? 'N/A'}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  md={11}
                  xs={12}
                  justify="space-between"
                  style={{}}
                >
                  <Grid item xs={5}>
                    <Typography style={{ fontSize: '14px', fontWeight: 700 }}>
                      Absent:
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography style={{ fontSize: '14px' }} align="start">
                      {darList?.absent}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  md={11}
                  xs={12}
                  justify="space-between"
                  style={{}}
                >
                  <Grid item xs={5}>
                    <Typography style={{ fontSize: '14px', fontWeight: 700 }}>
                      Total Workers:
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography style={{ fontSize: '14px' }} align="start">
                      {darList?.totalWorkers ?? 'N/A'}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item md={4} container>
                <Grid container item md={11} xs={12} justify="space-between">
                  <Grid item md={6} xs={5}>
                    <Typography style={{ fontSize: '14px', fontWeight: 700 }}>
                      Total Online Workers:
                    </Typography>
                  </Grid>
                  <Grid item md={6} xs={7}>
                    <Typography style={{ fontSize: '14px' }} align="start">
                      {darList?.totalOnline}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  md={11}
                  xs={12}
                  justify="space-between"
                  style={{}}
                >
                  <Grid item md={6} xs={5}>
                    <Typography style={{ fontSize: '14px', fontWeight: 700 }}>
                      Total Offline Workers:
                    </Typography>
                  </Grid>
                  <Grid item md={6} xs={7}>
                    <Typography style={{ fontSize: '14px' }} align="start">
                      {darList?.totalOffline}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} style={{ padding: '10px 0' }}>
              <Typography
                align="center"
                variant="subtitle1"
                style={{ fontWeight: 700 }}
                component="h2"
              >
                DAILY ATTENDANCE REPORT
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table
                  className={classes.table}
                  size="small"
                  aria-label="DAR Table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.DARAndDLRColums}>
                        #
                      </TableCell>
                      <TableCell className={classes.DARAndDLRColums}>
                        Contractor ID
                      </TableCell>
                      <TableCell className={classes.DARAndDLRColums}>
                        Contractor Name
                      </TableCell>
                      <TableCell className={classes.DARAndDLRColums}>
                        Skillset
                      </TableCell>
                      <TableCell className={classes.DARAndDLRColums}>
                        Skilled Workers
                      </TableCell>
                      <TableCell className={classes.DARAndDLRColums}>
                        Unskilled Workers
                      </TableCell>
                      <TableCell className={classes.DARAndDLRColums}>
                        Male Workers
                      </TableCell>
                      <TableCell className={classes.DARAndDLRColums}>
                        Female Workers
                      </TableCell>
                      <TableCell className={classes.DARAndDLRColums}>
                        Over Time{' '}
                      </TableCell>
                      {/* <TableCell className={ classes.DARAndDLRColums }>Check In	</TableCell>
											<TableCell className={ classes.DARAndDLRColums }>Check Out	</TableCell> */}
                      <TableCell className={classes.DARAndDLRColums}>
                        Absent
                      </TableCell>{' '}
                      <TableCell className={classes.DARAndDLRColums}>
                        Total
                      </TableCell>{' '}
                      <TableCell className={classes.DARAndDLRColums}>
                        Quit Job
                      </TableCell>{' '}
                      <TableCell className={classes.DARAndDLRColums}>
                        Reporting Time
                      </TableCell>
                      <TableCell className={classes.DARAndDLRColums}>
                        Remark
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {darList?.dailyAttendance?.map((item, index) => (
                      <TableRow key={index + 1}>
                        <TableCell align="center" style={{ fontSize: '12px' }}>
                          {index + 1}
                        </TableCell>

                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          style={{ fontSize: '12px' }}
                        >
                          {item?.contractorId}
                        </TableCell>
                        <TableCell align="left" style={{ fontSize: '12px' }}>
                          {item?.contractor ?? 'N/A'}
                        </TableCell>
                        <TableCell align="left" style={{ fontSize: '12px' }}>
                          {item?.skillName ?? 'N/A'}
                        </TableCell>
                        <TableCell align="center" style={{ fontSize: '12px' }}>
                          {item?.skilledWorkers ?? 'N/A'}
                        </TableCell>

                        <TableCell align="center" style={{ fontSize: '12px' }}>
                          {item?.unSkilledWorkers ?? 'N/A'}
                        </TableCell>
                        <TableCell align="center" style={{ fontSize: '12px' }}>
                          {item?.maleWorkers ?? 'N/A'}
                        </TableCell>
                        <TableCell align="center" style={{ fontSize: '12px' }}>
                          {item?.femaleWorkers ?? 'N/A'}
                        </TableCell>

                        <TableCell align="center" style={{ fontSize: '12px' }}>
                          {item.overtime ?? 'N/A'}
                        </TableCell>
                        {/* <TableCell align='center' style={ { fontSize: "12px" } }>N/A</TableCell>
												<TableCell align='center' style={ { fontSize: "12px" } }>N/A</TableCell> */}

                        <TableCell align="center" style={{ fontSize: '12px' }}>
                          {item?.absent ?? 'N/A'}
                        </TableCell>
                        <TableCell align="center" style={{ fontSize: '12px' }}>
                          {item?.total ?? 'N/A'}
                        </TableCell>
                        <TableCell align="center" style={{ fontSize: '12px' }}>
                          {item.quitJob ?? 'N/A'}
                        </TableCell>
                        <TableCell align="left" style={{ fontSize: '12px' }}>
                          {' '}
                          {item.checkIn ?? 'N/A'}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ fontSize: '12px' }}
                        ></TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Dialog>
        <Toaster />
        <Grid
          container
          style={{
            background: 'white',
            padding: '1em',
            borderRadius: '10px',
          }}
        >
          {renderAttendenceList()}
        </Grid>
      </Container>
    </Page>
  );
}

export default Attendance;
