import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';

import DoneAllIcon from '@material-ui/icons/DoneAll';
import arrow from '../../../assests/arrow.png';
import pin from '../../../assests/Pin.png';
import DescriptionRow from './DescriptionRow';
import { DynamicCell } from '../SOWTable';

const TitleRow = (props) => {
  const { classes, title, onSave, grossProgress } = props;

  const initialQuantity = title?.Descriptions[0]?.RemainingQuantity || '';
  const rowBoqId = title?.Descriptions[0]?.BOQId || '';

  const successIndicatorColor = '#81B734';
  const defaultColor = 'rgba(1,1,1,1)';

  const [imgVisible, setImgVisible] = useState(null);
  const [progress, setProgress] = useState('');
  const [remarks, setRemarks] = useState('');
  const [newQuantity, setNewQuantity] = useState(initialQuantity);
  const [errors, setErrors] = useState({ progress: false, remarks: false });
  const [activeColor, setActiveColor] = useState(defaultColor);

  const lockUpdates = title.Descriptions[0].IsLocked;

  const checkTouchedStatus = () => {
    if (!grossProgress[title.Descriptions[0].BOQId]) {
      grossProgress[title.Descriptions[0].BOQId] = {
        sowId: title.Descriptions[0].ScopeOfWorkId,
        setErrorFallback: setErrors,
      };
    }
  };

  const handleProgressChange = (e) => {
    if (errors.progress) {
      setErrors((prev) => ({ ...prev, progress: false }));
    }
    const updatedProgress = e.target.value;
    if (!updatedProgress || updatedProgress.match(/^\d+$/)) {
      setProgress(updatedProgress);
      checkTouchedStatus();
      grossProgress[title.Descriptions[0].BOQId].quantity = updatedProgress;
    }
  };

  const handleRemarksChange = (e) => {
    if (errors.remarks) {
      setErrors((prev) => ({ ...prev, remarks: false }));
    }
    const updatedRemarks = e.target.value;
    setRemarks(updatedRemarks);
    checkTouchedStatus();
    grossProgress[title.Descriptions[0].BOQId].remarks = updatedRemarks;
  };

  const handleImage = (e, id) => {
    const uploadedImage = e.target.files[0];
    e.stopPropagation();
    if (uploadedImage) {
      checkTouchedStatus();
      grossProgress[title.Descriptions[0].BOQId].image = uploadedImage;
      setImgVisible(uploadedImage);
    }
  };

  const cleanUp = () => {
    setRemarks('');
    setProgress('');
    setImgVisible(null);
  };

  const errorFallback = () => {
    setNewQuantity(initialQuantity);
    setErrors({ remarks: true, progress: true });
    toast.error('There was an error saving the progress.');
  };

  const preamptSuccess = () => {
    setActiveColor(successIndicatorColor);
    setTimeout(() => {
      setActiveColor(defaultColor);
    }, 5000);
  };

  const handleSave = (e) => {
    e.stopPropagation();
    if (!progress) {
      toast.error('Progress field is required.');
      return;
    }
    const { BOQId, ScopeOfWorkId } = title.Descriptions[0];
    onSave(BOQId, progress, remarks, imgVisible, ScopeOfWorkId, errorFallback);
    preamptSuccess();
    setNewQuantity((prevQuantity) => prevQuantity - progress);
    cleanUp();
  };

  const renderTitleRow = () => {
    return (
      <Grid
        item
        container
        alignItems="center"
        style={{
          backgroundColor: 'rgba(230, 241, 214, 1)',
          borderRadius: 10,
          padding: '5px 10px',
          border: '1px solid rgba(1,1,1,0.1)',
        }}
      >
        <Grid item container xs={12}>
          <Grid item style={{ width: '10%' }}>
            <Typography style={{ fontSize: 10 }}>Title</Typography>
            <DynamicCell fontSize={12} value={title?.Title || ''} />
          </Grid>
          <Grid item style={{ width: '14%' }}>
            <Typography style={{ fontSize: 10 }}>Description</Typography>
            <DynamicCell
              fontSize={12}
              value={title?.Descriptions[0]?.Description || ''}
            />
          </Grid>
          <Grid item style={{ width: '6%' }}>
            <Typography style={{ fontSize: 10 }}>Unit</Typography>
            <Typography> {title?.Descriptions[0]?.UnitName}</Typography>
          </Grid>
          <Grid item style={{ width: '10%' }}>
            <Typography
              className={classes.remainingWorkValue}
              style={{ color: activeColor, fontSize: 10 }}
            >
              Remaining Work
            </Typography>
            <Typography
              className={classes.remainingWorkValue}
              style={{ color: activeColor }}
            >
              {newQuantity}
            </Typography>
          </Grid>
          <Grid item style={{ width: '6%' }}>
            <Typography style={{ fontSize: 10 }}>Rate</Typography>
            <Typography>{title?.Descriptions[0]?.Rate}</Typography>
          </Grid>
          <Grid item style={{ width: '8%' }}>
            <Typography
              className={classes.remainingWorkValue}
              style={{ color: activeColor, fontSize: 10 }}
            >
              Amount
            </Typography>
            <Typography
              className={classes.remainingWorkValue}
              style={{ color: activeColor }}
            >
              {newQuantity * title?.Descriptions[0]?.Rate}
            </Typography>
          </Grid>
          <Grid item style={{ width: '12%' }}>
            <TextField
              label={'Todays Progress'}
              size="small"
              variant="filled"
              InputProps={{
                disableUnderline: !errors.progress,
                style: {
                  borderRadius: '7px',
                  height: 45,
                },
              }}
              disabled={lockUpdates}
              type="text"
              InputLabelProps={{
                style: {
                  color: '#8E99A8',
                  fontSize: 'small',
                },
              }}
              style={{ margin: '5px' }}
              required
              value={progress || ''}
              onChange={handleProgressChange}
              error={errors.progress}
            />
          </Grid>
          <Grid item style={{ width: '12%' }}>
            <TextField
              label="Remarks"
              size="small"
              variant="filled"
              InputProps={{
                disableUnderline: !errors.remarks,
                style: {
                  borderRadius: '7px',
                  height: 45,
                },
              }}
              disabled={lockUpdates}
              type="tel"
              InputLabelProps={{
                style: {
                  color: '#8E99A8',
                  fontSize: 'small',
                },
              }}
              style={{ margin: '5px' }}
              value={remarks}
              onChange={handleRemarksChange}
              error={errors.remarks}
            />
          </Grid>
          <Grid item style={{ width: '12%', paddingTop: 4 }}>
            <input
              accept="image/*"
              className={classes.input1}
              id={`icon-button-file-${rowBoqId}`}
              type="file"
              onChange={(e) => handleImage(e)}
            />

            <label
              htmlFor={`icon-button-file-${rowBoqId}`}
              className={classes.ImagePlaceHolder}
            >
              {imgVisible ? (
                <img
                  src={`${URL?.createObjectURL(imgVisible)}`}
                  alt="project url"
                  style={{
                    width: '50px',
                    height: '50px',
                    borderRadius: '50%',
                  }}
                />
              ) : (
                <Button
                  onClick={() => {
                    document
                      .getElementById(`icon-button-file-${rowBoqId}`)
                      .click();
                  }}
                  disabled={lockUpdates}
                  style={{
                    border: '1px dotted rgba(1,1,1,0.2)',
                    backgroundColor: 'white',
                    padding: '5px 15px',
                    fontSize: 11,
                    fontWeight: 'normal',
                    textTransform: 'capitalize',
                    height: 45,
                    borderRadius: 8,
                  }}
                  startIcon={
                    <img
                      src={pin}
                      alt="upload images"
                      style={{
                        backgroundColor: 'rgba(1,1,1,0.1)',
                        padding: '5px 10px',
                      }}
                    />
                  }
                >
                  Upload Image
                </Button>
              )}
            </label>
          </Grid>
          <Grid item style={{ width: '10%', paddingTop: 4 }}>
            <Button
              fullWidth
              className={classes.buttonPurple}
              style={{
                color: '#fff',
                padding: '10px 0',
                borderRadius: '7px',
              }}
              disabled={lockUpdates}
              size="small"
              endIcon={
                <img src={arrow} alt="submit" style={{ fontSize: '15px' }} />
              }
              onClick={handleSave}
            >
              Save
            </Button>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          style={{ flexGrow: 1 }}
          justify="flex-end"
          alignItems="center"
        >
          {/* <DoneAllIcon
            style={{ fontSize: 11, color: '#8E99A8', marginRight: 5 }}
          />
          <Typography style={{ fontSize: 10, color: '#8E99A8' }}>
            Last Updated 4 hours ago
          </Typography> */}
        </Grid>
      </Grid>
    );
  };
  return title?.Descriptions?.length > 1 ? (
    <Accordion style={{ width: '100%', border: 'none', boxShadow: 'none' }}>
      <AccordionSummary style={{ padding: 0, margin: 0 }}>
        {renderTitleRow()}
      </AccordionSummary>
      <AccordionDetails
        style={{
          width: '100%',
          display: 'inline-block',
          padding: 0,
          margin: 0,
        }}
      >
        {title?.Descriptions?.slice(1).map((description, descIndex) => (
          <Grid style={{ width: '100%' }}>
            <DescriptionRow
              onSave={onSave}
              index={descIndex}
              classes={classes}
              description={description}
              grossProgress={grossProgress}
            />
          </Grid>
        ))}
      </AccordionDetails>
      <Toaster />
    </Accordion>
  ) : (
    renderTitleRow()
  );
};

export default React.memo(TitleRow);
